import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PropTypes } from "prop-types";
import LazyLoad from "react-lazyload";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

import { Link } from "../al_components/ALLink";
import ALButton from "../al_components/ALButton";
import ALSkeleton from "../al_components/ALSkeleton";
import ALVideo from "../al_components/ALVideo";
import RichTextContentful from "../RichTextContentful";
import { ComponentType } from "../../constants/ComponentType";

import * as Styles from "./textImage/styles.module.scss";

function TextImage({ content, componentType }) {
  const { isMobile } = useDeviceDetect();
  let mobileImage = null;
  let desktopImage = null;

  if (content.image) {
    if (componentType === ComponentType.LUXE) {
      mobileImage = <img src={content.image.mobileImage?.file?.url} alt={content.image.altText} />;
      desktopImage = (
        <img src={content.image.desktopImage?.file?.url} alt={content.image.altText} />
      );
    } else {
      if (content.image.mobileImage?.mimeType === "image/gif") {
        mobileImage = (
          <img src={content.image.mobileImage?.file?.url} alt={content.image.altText} />
        );
      } else {
        mobileImage = (
          <GatsbyImage
            image={getImage(content.image.mobileImage?.gatsbyImageData)}
            alt={content.image.altText}
          />
        );
      }

      if (content.image.desktopImage?.mimeType === "image/gif") {
        desktopImage = (
          <img src={content.image.desktopImage?.file?.url} alt={content.image.altText} />
        );
      } else {
        desktopImage = (
          <GatsbyImage
            image={getImage(content.image.desktopImage?.gatsbyImageData)}
            alt={content.image.altText}
          />
        );
      }
    }
  }

  const image = useResponsiveWithHydrationFix(mobileImage, desktopImage);

  return (
    <div
      className={`${Styles.textImage__wrapper} ${Styles[componentType?.toLowerCase()]} ${
        content.imagevideoPosition === "left" ? Styles.textImage__wrapperReverse : ""
      } id-${content.contentful_id}`}
      style={{ backgroundColor: content.backgroundColor }}
    >
      <div className={Styles.textImage__content}>
        <h2
          className={`h2 h2--bold h2--uc ${Styles.textImage__title}`}
          style={{ color: isMobile ? content.textColors?.mobile : content.textColors?.desktop }}
        >
          {content.title}
        </h2>
        <div
          className={`text ${Styles.textImage__description}`}
          style={{ color: isMobile ? content.textColors?.mobile : content.textColors?.desktop }}
        >
          {componentType === ComponentType.LUXE ? (
            <p>{content.description}</p>
          ) : (
            <RichTextContentful input={content.description?.raw} />
          )}
        </div>
        <div className={Styles.textImage__buttons}>
          {content.links.map((l) => {
            if (l.title !== "empty" && l.title !== "null") {
              return (
                <Link
                  key={`textImageLink_${l.title}`}
                  to={`${l.link.link}`}
                  className={`subtext subtext--uc subtext--bold ${Styles.textImage__button}`}
                >
                  <ALButton variant="primary" size="medium" fullWidth>
                    {l.title}
                  </ALButton>
                </Link>
              );
            }
          })}
        </div>
      </div>
      {image !== null && (
        <div className={Styles.textImage__image}>
          <LazyLoad
            className={Styles.textImage__imageLazyload}
            style={{ width: "100%" }}
            once
            placeholder={<ALSkeleton width="100%" height="100%" />}
          >
            {image}
          </LazyLoad>
        </div>
      )}
      {image === null && content.videoId && content.videoId !== "null" && (
        <div className={Styles.textImage__video}>
          <ALVideo
            key={content.videoId}
            videoId={content.videoId}
            customClasses={{
              container: Styles.textImage__videoContainer,
              skeleton_container: "",
              skeleton_image: "",
              vimeo_container: Styles.textImage__vimeo,
              image_thumbnail: Styles.textImage__videoThumbnail,
              video_container: Styles.textImage__videoThumbnail,
              video: Styles.textImage__videoVimeo,
            }}
          />
        </div>
      )}
    </div>
  );
}

TextImage.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
      PropTypes.shape({
        raw: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    image: PropTypes.shape({
      altText: PropTypes.string,
    }),
    videoId: PropTypes.string,
    imagevideoPosition: PropTypes.oneOf(["left", "right"]),
    links: PropTypes.arrayOf(PropTypes.shape({})),
    textColors: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
  }),
};

export default withALErrorBoundary({
  name: "TextImage",
  priority: "P2",
})(TextImage);
